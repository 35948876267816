<template>
  <div class="offer-teaser">
    <div class="offer-img ratio ratio-1x1">
      <div v-if="imgSrc" :style="`background-image: url(${imgSrc});`" @click="onClick">
        <span class="sr-only">{{ imgAlt }}</span>
      </div>

      <c-placeholder-image v-else size="75"/>
    </div>

    <div class="offer-content">
      <div class="offer-header">
        <slot name="header"/>
      </div>

      <div class="offer-body">
        <c-carousel
          class="offer-products"
          :autoplay="false"
          :adaptiveHeight="false"
          :slidesToShow="4"
          :slidesToScroll="4"
          :responsive="[
            {
              breakpoint: BREAKPOINTS.md,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3
              }
            },
            {
              breakpoint: BREAKPOINTS.sm,
              settings: {
                adaptiveHeight: true,
                slidesToShow: 2,
                slidesToScroll: 2
              }
            }
          ]"
        >
          <slot/>
        </c-carousel>

        <div v-if="$slots.productcount" class="offer-product-count">
          <small><slot name="productcount"/></small>
        </div>
      </div>

      <div class="offer-footer">
        <a class="offer-actionbutton btn btn-document btn-sm" :href="href" ref="link">
          <slot name="actionbutton"/>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { BREAKPOINTS } from '@/assets/js/modules/scss-variables'

export default {
  name: 'OfferTeaser',
  props: {
    href: {
      type: String,
      required: true
    },
    imgSrc: {
      type: String,
      default: null
    },
    imgAlt: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      BREAKPOINTS
    }
  },
  methods: {
    onClick (e) {
      this.$refs.link.dispatchEvent(new MouseEvent('click', { ctrlKey: e.ctrlKey }))
    }
  }
}
</script>

<style lang="scss">
$offer-teaser-gap: $grid-gutter-width !default;
$offer-teaser-padding-y: $component-padding-y * 0.6 !default;
$offer-teaser-padding-x: $component-padding-x !default;
$offer-teaser-bg: $card-bg !default;
$offer-teaser-font-size: $font-size-base * 0.875 !default;

$offer-teaser-img-width: 31% !default;
$offer-teaser-img-transition-in: transform ease-out 200ms !default;
$offer-teaser-img-transition-out: transform ease-out 200ms !default;

$offer-teaser-product-padding-y: 0 !default;
$offer-teaser-product-padding-x: $spacer * 0.5 !default;
$offer-teaser-product-font-size: $font-size-base * 0.75 !default;
$offer-teaser-product-any-gap: $spacer * 0.5 !default;

$offer-teaser-product-dots-gap: $spacer * 0.5 !default;

$offer-teaser-actionbutton-animation: h-bounce 400ms ease-out 1 !default;

$offer-teaser-img-lg-width: 34% !default;

$offer-teaser-img-md-width: 36% !default;

$offer-teaser-img-sm-width: 100% !default;

.offer-teaser {
  @include make-leaf($leaf-border-radius-lg);
  display: flex;
  position: relative;
  overflow: hidden;
  margin-bottom: $offer-teaser-gap;
  background-color: $offer-teaser-bg;
  font-size: $offer-teaser-font-size;

  &:last-child {
    margin-bottom: 0;
  }

  .offer-img {
    @include make-leaf($leaf-border-radius-lg, 'top');
    width: $offer-teaser-img-width;
    cursor: pointer;

    div {
      background: no-repeat center center;
      background-size: cover;
      transform-origin: center right;
      transform: scale(1);
      transition: $offer-teaser-img-transition-out;
    }
  }

  .offer-content {
    flex: 1 0 0%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;

    .offer-header {
      overflow: hidden;
      padding: $offer-teaser-padding-y $offer-teaser-padding-x 0;
    }

    .offer-body {
      padding: ($offer-teaser-padding-y * 0.5) $offer-teaser-padding-x;
    }

    .offer-footer {
      padding: 0 $offer-teaser-padding-x $offer-teaser-padding-y;
    }

    .offer-header,
    .offer-body,
    .offer-footer {
      @include clearfix();

      > * {
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .offer-title {}

  .offer-products {
    margin: 0;

    .slick-list {
      @include reset-leaf();
    }

    .slick-slide {
      [tabindex="-1"] {
        padding: $offer-teaser-product-padding-y $offer-teaser-product-padding-x;
        font-size: $offer-teaser-product-font-size;

        * {
          + * {
            margin-top: $offer-teaser-product-any-gap;
          }
        }

        a {
          display: block;
        }

        p {
          overflow: hidden;
          text-overflow: ellipsis;
          text-align: center;
          white-space: nowrap;
        }
      }
    }

    .slick-dots {
      margin-top: $offer-teaser-product-dots-gap;
      margin-bottom: 0;
    }
  }

  .offer-product-count {
    line-height: 1;
  }

  .offer-actionbutton {
    display: block;
    float: right;
  }

  &:hover {
    .offer-img {
      > div {
        transform: scale(1.05);
        transition: $offer-teaser-img-transition-in;
      }
    }

    .offer-actionbutton {
      animation: $offer-teaser-actionbutton-animation;
    }
  }

  @include media-breakpoint-down(lg) {
    .offer-img {
      width: $offer-teaser-img-lg-width;
    }
  }

  @include media-breakpoint-down(md) {
    .offer-img {
      width: $offer-teaser-img-md-width;
    }
  }

  @include media-breakpoint-down(sm) {
    flex-direction: column;

    .offer-img {
      width: $offer-teaser-img-sm-width;
      transform-origin: bottom center;
    }

    .offer-content {
      .offer-body {
        padding-top: $offer-teaser-padding-y;
        padding-bottom: $offer-teaser-padding-y;
      }
    }

    .offer-products {
      .slick-slide {
        [tabindex="-1"] {
          p {
            overflow: visible;
            white-space: normal;
          }
        }
      }
    }
  }
}
</style>

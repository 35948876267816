var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"offer-teaser"},[_c('div',{staticClass:"offer-img ratio ratio-1x1"},[(_vm.imgSrc)?_c('div',{style:(("background-image: url(" + _vm.imgSrc + ");")),on:{"click":_vm.onClick}},[_c('span',{staticClass:"sr-only"},[_vm._v(_vm._s(_vm.imgAlt))])]):_c('c-placeholder-image',{attrs:{"size":"75"}})],1),_c('div',{staticClass:"offer-content"},[_c('div',{staticClass:"offer-header"},[_vm._t("header")],2),_c('div',{staticClass:"offer-body"},[_c('c-carousel',{staticClass:"offer-products",attrs:{"autoplay":false,"adaptiveHeight":false,"slidesToShow":4,"slidesToScroll":4,"responsive":[
          {
            breakpoint: _vm.BREAKPOINTS.md,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3
            }
          },
          {
            breakpoint: _vm.BREAKPOINTS.sm,
            settings: {
              adaptiveHeight: true,
              slidesToShow: 2,
              slidesToScroll: 2
            }
          }
        ]}},[_vm._t("default")],2),(_vm.$slots.productcount)?_c('div',{staticClass:"offer-product-count"},[_c('small',[_vm._t("productcount")],2)]):_vm._e()],1),_c('div',{staticClass:"offer-footer"},[_c('a',{ref:"link",staticClass:"offer-actionbutton btn btn-document btn-sm",attrs:{"href":_vm.href}},[_vm._t("actionbutton")],2)])])])}
var staticRenderFns = []

export { render, staticRenderFns }